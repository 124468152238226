






import Vue from 'vue';
import { mapState } from 'vuex';
import AuthService from '@/services/auth.service';

export default Vue.extend({
    data() {
        return {
            authService: new AuthService(),
        };
    },
    computed: {
        ...mapState(['locale']),
        ...mapState('auth', ['isAuthenticated']),
        ...mapState('vendor', ['vendorInfo']),
    },
    created() {
        // this is to detect and prevent people from clicking the back button after logging out
        // if the back button is pressed, do the authentication check again
        // of if the user logged in and immediately hit the back button
        window.onpopstate = () => {
            if (!this.isAuthenticated && this.$route.name !== 'login' && this.$route.name !== 'register') {
                this.$router.push({ name: 'login', params: { locale: this.locale } });
            }
            if (this.isAuthenticated && (this.$route.name === 'login' || this.$route.name === 'register')) {
                this.$router.push({ name: 'index', params: { locale: this.locale } });
            }
        };
    },
    methods: {
        setTheme() {
            const { colors } = this.vendorInfo.theme;
            const bodyStyle = document.body.style;

            const toKebabCase = (string: string) => {
                return string.replace(/[A-Z]/g, '-$&').toLowerCase();
            };

            // loop through each color and set global variable
            for (const key of Object.keys(colors)) {
                bodyStyle.setProperty(`--${toKebabCase(key)}`, colors[key]);
            }
        },
    },
    mounted() {
        this.setTheme();
        document.title = this.vendorInfo.dashboardTitle;
    },
});
