import { MutationTree } from 'vuex';
import { IWizardState, WizardAction } from './types';
import { state as defaultState } from './state';

export const mutations: MutationTree<IWizardState> = {
    ENABLE: (state: IWizardState) => {
        state.enabled = true;
    },
    DISABLE: (state: IWizardState) => {
        state.enabled = false;
    },
    SET_WIZARD_ACTION: (state: IWizardState, payload: WizardAction) => {
        state.action = payload;
    },
    DELETE_WIZARD_ACTION: (state: IWizardState) => {
        state.action = undefined;
    },
};
