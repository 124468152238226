





import Vue from 'vue';

export default Vue.extend({
    props: {
        tag: {
            type: String,
            default: 'p',
        },
        variant: {
            type: String,
            default: '',
        },
        bold: {
            type: Boolean,
            default: false,
        },
        inverse: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classNames(): Object {
            const baseStyles = {
                'font-weight-bold': this.bold,
                white: this.inverse,
            };

            switch (this.variant) {
            case 'title':
                return {
                    ...baseStyles,
                    title: true,
                };
            case 'title-thin':
                return {
                    ...baseStyles,
                    'title-thin': true,
                };
            case 'subtitle':
                return {
                    ...baseStyles,
                    subtitle: true,
                };
            case 'subtext':
                return {
                    ...baseStyles,
                    subtext: true,
                };
            case 'error':
                return {
                    ...baseStyles,
                    error: true,
                };
            default:
                return {
                    ...baseStyles,
                };
            }
        },
    },
});
