import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { IRootState } from '@/store/types';
/* eslint-disable-next-line */
import { mutations } from '@/store/mutations';
import { actions } from '@/store/actions';
import { getters } from '@/store/getters';
// modules
import { auth } from '@/store/auth';
import { theme } from '@/store/theme/index';
import { user } from '@/store/user/index';
import { vendor } from '@/store/vendor';
import { wizard } from '@/store/wizard/index';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<IRootState>({
    key: 'LoginId',
    storage: window.localStorage,
    // reducer acts as a whitelist to specify which
    // modules or root states to store in persist localstorage
    reducer: (state: IRootState) => {
        return {
            // root state
            locale: state.locale,
            version: state.version,
            // modules
            auth: state.auth,
            theme: state.theme,
            user: state.user,
        };
    },
});

export function getDefaultState() {
    return {
        version: '1.0.0',
        locale: 'en',
    };
}

const store: StoreOptions<IRootState> = {
    strict: process.env.NODE_ENV !== 'production',
    state: getDefaultState(),
    getters,
    mutations,
    actions,
    modules: {
        auth,
        theme,
        user,
        wizard,
        vendor,
    },
    plugins: [vuexLocal.plugin],
};
export default new Vuex.Store<IRootState>(store);
