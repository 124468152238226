import { GetterTree, Getter } from 'vuex';
import { IAuthState } from '@/store/auth/types';
import { IRootState } from '@/store/types';

export const getters: GetterTree<IAuthState, IRootState> = {
    isAuthenticated: (state: IAuthState) => (): boolean => state.isAuthenticated,
    invitationMode: (state: IAuthState) => (): boolean => state.invitationMode,
    email: (state: IAuthState) => (): string => state.email,
    accessCode: (state: IAuthState) => (): string => state.accessCode,
};
