import Vue, { PluginObject } from 'vue';
import axios, {AxiosRequestConfig, HeadersDefaults} from 'axios';
import { LoginidError, getErrorMessageByCode } from '../utils/errors';
import store from '../store';

const UNAUTHORIZED = 401;
const PAYMENT_REQUIRED = 402;
const httpOptions = {
    baseURL: '/api/',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
};

axios.defaults.withCredentials = true;

const instance = axios.create(httpOptions);

instance.interceptors.response.use(

    (response) => {
        const { headers } = response;
        instance.defaults.headers.common['x-csrf-token'] = headers['x-csrf-token'];
        return response;
    },
    async (e: any): Promise<any> => {
        const { status, headers, data } = e.response;
        if (headers['x-csrf-token']) {
            instance.defaults.headers.common['x-csrf-token'] = headers['x-csrf-token'];
        }
        if (status === UNAUTHORIZED) {
            store.dispatch('timeoutLogout');
        }
        /*
        // old check authentication code
        if (e.response.status === UNAUTHORIZED) {
            if (e.response.data.errorCode === 'UNAUTHORIZED_INVALID_SPA_JWT') {
                if (e.config.url === `${e.config.baseURL}/auth/checkToken`) {
                    return store.dispatch('logout');
                    }
                    await instance.get('/auth/checkToken');
                    return axios(e.config);
                }
            }
             */

        const { code, errors, message } = data;
        throw new LoginidError(code, status === PAYMENT_REQUIRED ? message : getErrorMessageByCode(code), errors);
    },
);

export const HttpPlugin: PluginObject<any> = {
    install: () => {
        Vue.prototype.$http = instance;
    },
};

export default instance;
