import Vue from 'vue';

// we are loading the entire moment library for this one occurance
// we can write our own MMMM DD YYYY formatter to save package
// const moment = require('moment/src/moment');

const formatDate = (time: string) => {
    // Create a date object from the timestamp
    const date = new Date(time);

    // Create a list of names for the months
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    // return a formatted date
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}; ${date.getHours()}:${date.getMinutes()}`;
};
Vue.filter('date', (date: string) => formatDate(date));
