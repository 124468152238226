import { OrganizationStructure, EditOrgNamePayload, SetSubPayload } from './types';

export const actions = {
    setOrganizationId({ commit, dispatch }: any, payload: string) {
        commit('SET_ORGANIZATION_ID', payload);
        dispatch('setRecentOrganizations', payload);
    },
    setOrganizations({ commit }: any, payload: OrganizationStructure[]) {
        commit('SET_ORGANIZATIONS', payload);
    },
    setRecentOrganizations({ commit, state }: any, payload: string) {
        // get current local storage info
        const localKey = `${state.userId}_rw`;
        const savedNamespaceList = window.localStorage.getItem(localKey);
        // populate set (don't want repeats)
        const namespaceListSet = new Set([payload]);
        if (savedNamespaceList) {
            const parsedSWL: string[] = [...JSON.parse(savedNamespaceList)];
            parsedSWL.forEach((i) => namespaceListSet.add(i));
        }
        const namespaceList = [...namespaceListSet];
        // update localstorage
        window.localStorage.setItem(localKey, JSON.stringify(namespaceList));
        // set vuex state
        commit('SET_RECENT_ORGANIZATIONS', namespaceList);
    },
    addOrganization({ commit }: any, payload: OrganizationStructure) {
        commit('ADD_ORGANIZATIONS', payload);
    },
    clearOrganizations({ commit }: any) {
        commit('CLEAR_ORGANIZATIONS');
    },
    // setPendingUpgradeRequest({ commit }: any, payload: string) {
    //     commit('SET_PENDING_UPGRADE_REQUEST', payload);
    // },
    setOrganizationNameById({ commit }: any, payload: EditOrgNamePayload) {
        commit('SET_ORGANIZATION_NAME_BY_ID', payload);
    },
    setLegacyTrue({ commit }: any) {
        commit('SET_LEGACY_TRUE');
    },

    upgradeSubscription({ commit, getters }: any, payload?: string) {
        const orgId = getters.getOrganizationId();
        commit('SET_SUBSCRIPTION_BY_ORG', { orgId, subscription: payload || 'enterprise' }as SetSubPayload);
    },

    downgradeSubscription({ commit, getters }: any, payload?: string) {
        const orgId = getters.getOrganizationId();
        commit('SET_SUBSCRIPTION_BY_ORG', { orgId, subscription: payload || 'standard' }as SetSubPayload);
    },
};
