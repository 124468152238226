export const actions = {
    setInvitationMode({ commit }: any, payload: string) {
        commit('SET_INVITATION_MODE', payload);
    },
    setEmail({ commit }: any, payload: string) {
        commit('SET_EMAIL', payload);
    },
    setAccessCode({ commit }: any, payload: string) {
        commit('SET_ACCESS_CODE', payload);
    },
};
