import { WizardAction } from './types';

export const actions = {
    enable({ commit }: any) {
        commit('ENABLE');
    },
    setWizardAction({ commit }: any, payload: WizardAction) {
        commit('SET_WIZARD_ACTION', payload);
    },
    completeWizard({ commit }: any) {
        commit('DISABLE');
        commit('DELETE_WIZARD_ACTION');
    },
};
