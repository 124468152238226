export interface OrganizationStructure {
    createdAt: string;
    id: string;
    name: string;
    subscription: string;
    type: OrganizationType;
    roleId: string;
    status: string;
    pendingUpgradeRequest?: boolean;
}

export interface IUserState {
    username: string;
    organizationId: string;
    userId: string;
    type: string;
    organizations: OrganizationStructure[];
    recentOrganizations: string[],
    legacy: boolean,
}

export enum OrganizationType {
    TPP = 'Technology Platform Partner',
    TRP = 'Technology Reseller Partner',
    IRA = 'Independent Reseller Agent',
    AP = 'Affiliate Partner',
    BE = 'Business Enterprise',
    SelfServe = 'Self Serve',
    LoginID = 'LoginID',
}

export interface EditOrgNamePayload {
    name: string;
    id: string;
}

export interface SetSubPayload {
    orgId: string;
    subscription: string;
}
