import { GetterTree, Getter } from 'vuex';
import { IRootState } from '@/store/types';
import { OrganizationDataStructure } from '@/services/organization.service';
import { IUserState, OrganizationType as OT, OrganizationStructure } from './types';

const isInList = (str: string, searchList: string[]): boolean => {
    // prettier-ignore
    return searchList.find(item => item === str) !== undefined;
};

const getCurrentOrganization = (state: IUserState): OrganizationStructure|undefined => {
    return state.organizations.find(org => org.id === state.organizationId);
};

const getOrganizationType = (state: IUserState): OT => {
    return getCurrentOrganization(state)?.type || OT.LoginID;
};

export const getters: GetterTree<IUserState, IRootState> = {
    getUsername: (state: IUserState) => (): string => state.username,
    getOrganizationId: (state: IUserState) => (): string => state.organizationId,
    getUserId: (state: IUserState) => (): string => state.userId,
    getUserType: (state: IUserState) => (): string => state.type,
    getOrganization: (state:IUserState) => (): OrganizationStructure|undefined => getCurrentOrganization(state),
    getOrganizationName: (state:IUserState) => (): string => getCurrentOrganization(state)?.name || '',
    getOrganizations: (state:IUserState) => (): OrganizationDataStructure[] => state.organizations,
    getRecentOrganizations: (state: IUserState) => (): string[] => state.recentOrganizations,
    getOrganizationSubscription: (state:IUserState) => (): string => getCurrentOrganization(state)?.subscription || '',
    getOrganizationType: (state: IUserState) => (): string => getOrganizationType(state),
    getUserRoleId: (state: IUserState) => (): string => getCurrentOrganization(state)?.roleId || '',
    // getPendingUpgradeRequest: (state: IUserState) => (): boolean => getCurrentOrganization(state)?.pendingUpgradeRequest || false,
    showClients: (state: IUserState) => (): boolean => isInList(getOrganizationType(state), [OT.TPP, OT.LoginID]),
    showAdditionalReports: (state: IUserState) => (): boolean => isInList(getOrganizationType(state), [OT.TPP, OT.LoginID]),
    allowedToAddPartner: (state: IUserState) => (): boolean => isInList(getOrganizationType(state), [OT.TPP, OT.LoginID]),
    isLegacy: (state: IUserState) => (): boolean => state.legacy,
};
