import router from '@/router';
import AnalyticsService from '@/services/analytics.service';

/* eslint-disable camelcase */
interface LoginParams {
    username: string;
    id: string;
    type: string;
}
/* eslint-enable camelcase */

export const actions = {
    setLocale({ commit }: any, payload: string) {
        commit('SET_LOCALE', payload);
    },
    setPluginReferral({ commit }: any, payload: string|undefined) {
        commit('SET_PLUGIN_REFERRAL', payload);
    },
    login({ commit }: any, payload: LoginParams) {
        commit('user/SET_USERNAME', payload.username);
        commit('user/SET_USERID', payload.id);
        commit('user/SET_USER_TYPE', payload.type);
        commit('auth/LOGIN');
    },
    timeoutLogout({ commit }: any) {
        commit('CLEAR');
        commit('auth/LOGOUT');

        router.push({ name: 'login' });
    },
    logout({ commit }: any) {
        try {
            const analyticsService = new AnalyticsService();
            analyticsService.logout();
        } catch (e) {
            throw new Error(e.message);
        } finally {
            commit('CLEAR');
            commit('auth/LOGOUT');
            commit('user/LOGOUT');
            router.push({ name: 'login' });
        }
    },
};
