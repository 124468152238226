import { AxiosResponse } from 'axios';
import http from './http.service';
import { AnalyticsDataFormat } from './interfaces';

const SERVICE_URL = '/dashboard/analytics';

export default class AnalyticsService {
    private http = http;

    async getData(
        startDate: string,
        endDate: string,
        organizationId: string,
        dimentions: string,
        ...metrics: string[]
    ): Promise<AnalyticsDataFormat[]> {
        const { data } = await this.http.get(`${SERVICE_URL}/data`, {
            params: {
                start_date: startDate,
                end_date: endDate,
                organization_id: organizationId,
                dimensions: dimentions,
                metrics: metrics.join(' '),
            },
        });
        return data;
    }

    async logout(): Promise<any> {
        const { data } = await this.http.put(`${SERVICE_URL}/logout`);
        return data;
    }

    async getEarningsData(startDate: string, endDate: string, organizationId: string): Promise<any> {
        const { data } = await this.http.get(`${SERVICE_URL}/earnings/data`, {
            params: {
                start_date: startDate,
                end_date: endDate,
                organization_id: organizationId,
            },
        });
        return data;
    }

    async getEarningsByOrg(orgId: string): Promise<any> {
        const { data } = await this.http.get(`${SERVICE_URL}/earnings/organizations/${orgId}`);
        return data;
    }

    async putEarningsByOrg(orgId: string, slope: number, multiplier: number, fee: number): Promise<any> {
        const { data } = await this.http.put(`${SERVICE_URL}/earnings/organizations/${orgId}`, {
            custom_slope: slope,
            custom_multiplier: multiplier,
            flat_fee: fee,
        });
        return data;
    }

    async getDataExport(startDate: string, endDate: string, organizationId: string): Promise<any> {
        return this.http.get(`${SERVICE_URL}/data/export?start_date=${startDate}&end_date=${endDate}&organization_id=${organizationId}`);
    }
}
