import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import LoginService from '@/services/login.service';
import { ProductionModes } from '@/services/environment.services';
import store from '../store';

const WithSpinner = () => import('@/components/with-spinner/with-spinner.vue');

Vue.use(VueRouter);

const vueMode = document.body.dataset.vueAppMode || '';
const useNewAuthCred = (document.body.dataset.vueAppNewAuthCred || '').toLowerCase() === 'true';

const routes = <any>[
    {
        path: '/:locale(en|id)',
        component: {
            render(c: any) {
                return c('router-view');
            },
        },
        children: [
            {
                path: 'oidc/auth/redirect',
                name: 'oidc-redirect',
                component: () => import('../views/sso_callback.vue'),
            },
            {
                path: 'verify/email/:code',
                name: 'email-validation',
                component: () => import('../views/email_validation.vue'),
            },
            {
                path: 'wordpress-directweb-plugin',
                name: 'wordpress-directweb-plugin',
                component: () => import('../views/wordpress_directweb_plugin.vue'),
            },
            {
                path: 'plugin-redirect',
                name: 'plugin-redirect',
                component: () => import('../views/plugin_redirect.vue'),
            },
            {
                path: 'register/:variant?',
                name: 'register',
                component: () => {
                    return useNewAuthCred
                        ? import('@/views/Auth.vue')
                        : import('@/components/v2/login_or_register/login_or_register.v2.vue');
                },
                props: {
                    isRegister: true,
                    // legacy props for v2
                    disablePersonalSelfServeRegistration: false,
                    allowManualLoginMethodSelection: false,
                    disableLanguageSelection: true,
                    disableLegacyAccount: vueMode === ProductionModes.prod,
                },
            },
            {
                path: 'login/:variant?',
                name: 'login',
                component: () => {
                    return useNewAuthCred
                        ? import('@/views/Auth.vue')
                        : import('@/components/v2/login_or_register/login_or_register.v2.vue');
                },
                props: {
                    isRegister: false,
                    // legacy props for v2
                    disableLanguageSelection: true,
                    disableLegacyAccount: vueMode === ProductionModes.prod,
                },
            },
            {
                path: 'code',
                name: 'code',
                component: () => import('@/views/Code.vue'),
            },
            {
                path: 'password/recover/:code',
                name: 'password-reset',
                component: () => import('../views/PasswordRecovery.vue'),
            },
            {
                path: '',
                component: () => import(/* webpackChunkName: "MainChunk" */ '../views/main.v2.vue'),
                children: [
                    {
                        path: '',
                        name: 'index',
                        redirect: { name: 'profile' },
                        // component: () => import(/* webpackChunkName: "IndexChunk" */ '../views/Index.vue'),
                    },
                    {
                        path: 'profile',
                        name: 'profile',
                        component: () => import(/* webpackChunkName: "ProfileChunk" */ '../views/profile.vue'),
                    },
                    {
                        path: 'integration',
                        name: 'integration',
                        component: WithSpinner,
                        props: {
                            component: async () => {
                                return import('../views/integration.v2.vue');
                            },
                        },
                    },
                    {
                        path: 'application/:options?',
                        name: 'application',
                        component: WithSpinner,
                        props: {
                            component: async () => {
                                return import('../views/Application.vue');
                            },
                        },
                    },
                    {
                        path: 'app/:id',
                        name: 'single-app-management',
                        component: WithSpinner,
                        props: {
                            component: async () => {
                                return import('../views/single-app.vue');
                            },
                        },
                    },
                    {
                        path: 'reports',
                        name: 'reports',
                        component: WithSpinner,
                        props: {
                            component: async () => {
                                return import(/* webpackChunkName: "ReportsChunk" */ '../views/Reports.vue');
                            },
                        },
                    },
                    {
                        path: 'user-management',
                        name: 'user-management',
                        component: () => import('../views/UserManagement.vue'),
                    },
                    {
                        path: 'clients',
                        name: 'clients',
                        component: () => import(/* webpackChunkName: "ClientsChunk" */ '../views/Clients.vue'),
                    },
                    {
                        path: 'organizational-integration',
                        name: 'organizational-integration',
                        component: () => import('../views/integration.v2.vue'),
                        props: (route: Route) => ({ propOrgId: route.query.id, name: route.query.name }),
                    },
                    {
                        path: 'partners',
                        name: 'partners',
                        component: () => import(/* webpackChunkName: "PartnersChunk" */ '../views/Partners.vue'),
                    },
                    {
                        path: 'deal-management',
                        name: 'deal-management',
                        component: WithSpinner,
                        props: {
                            component: async () => {
                                return import(/* webpackChunkName: "DMChunk" */ '../views/DealManagement.vue');
                            },
                        },
                    },
                    {
                        path: 'namespace',
                        name: 'namespace',
                        component: WithSpinner,
                        props: {
                            component: async () => {
                                return import('../views/namespace.vue');
                            },
                        },
                    },
                    {
                        path: 'admin-management',
                        name: 'admin-management',
                        component: WithSpinner,
                        props: {
                            component: async () => {
                                return import('../views/admin-management.vue');
                            },
                        },
                    },
                    {
                        path: '*',
                        redirect: { name: 'profile' },
                    },
                    //
                ],
            },
        ],
    },
    {
        path: '**',
        redirect: (to: Route) => {
            // anything that goes into this route doesn't have an language sign
            const newRoute = {
                ...to,
                path: `/${store.getters.locale()}${to.path}`,
                params: { ...to.params, locale: store.getters.locale() },
            };
            return newRoute;
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to: Route, from: Route, next: any) => {
    await store.dispatch('setLocale', to.params.locale || 'en');
    const isAuthenticated: boolean = store.getters['auth/isAuthenticated']();

    // if store is authenticated, then we need to go on server and verify that they are still authenticated before loading next page.
    if (isAuthenticated) {
        const loginService = new LoginService();
        try {
            loginService.isLoggedIn(); // this just dispatches a call to check if user is logged in
            // i shouldn't have to check the result because in the http service if the result is unauthorized, then it should automatically
            // timeout logout
        } catch (error) {
            // critical error happens here, not sure how to handle this yet.
        }
    }

    const authRoutes = ['login', 'register', 'oidc-redirect'];
    const unprotectedRoutes = [
        'wordpress-directweb-plugin',
        'tx',
        'password-reset',
        'email-validation',
        'plugin-redirect',
    ];

    const redirectRoutes = [
        'code',
    ];

    const query: any = {};

    if (to.name && redirectRoutes.includes(to.name)) {
        query.redirect_url = to.name;
    }

    // route filter logic here
    const locale = store.getters.locale();

    // hide /code behind feature flag
    if (to.name === 'code' && !useNewAuthCred) {
        router.push({ name: 'profile' });
    }

    if (!isAuthenticated && !authRoutes.includes(to.name || '') && !unprotectedRoutes.includes(to.name || '')) {
        router.push({ name: 'login', params: { locale }, query });
    } else if (isAuthenticated && (authRoutes.includes(to.name || '')) || !to.params.locale) {
        router.push({ name: 'profile', params: { locale } });
    } else {
        next();
    }
});

export default router;
