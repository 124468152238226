import { IUserState, OrganizationType } from './types';

export const state: IUserState = {
    username: '',
    organizations: [],
    organizationId: '',
    recentOrganizations: [],
    userId: '',
    type: '',
    legacy: false,
};
