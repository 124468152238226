import http from './http.service';
import { LoginResponse, GenericResponse, WebAuthnClientInfo } from './interfaces';
import { BrowserHelpersService } from './browser.helpers.service';

export default class AuthService {
    private loginURL = '/auth/authenticate';

    private registerURL = '/auth/register';

    private logoutURL = '/auth/logout';

    private metaURL = '/auth/meta';

    private fido2MakeCredentialURL = '/auth/meta/fido2/addAuthenticator';

    private fido2RemoveAuthrURL = '/auth/meta/fido2/removeAuthenticator';
    // private passwordSetURL    = '/auth/password/set';

    private userIsLoggedInURL = '/oidc/isLoggedIn';

    private http = http;

    private browserHelpers = new BrowserHelpersService();

    async initAuthentication(usernameOrEmail?: string): Promise<LoginResponse> {
        const { data } = await this.http.post(this.loginURL, { usernameOrEmail });
        return data;
    }

    submitAuthenticationResponse(response: any): Promise<LoginResponse> {
        return this.http.post(this.loginURL, response);
    }

    submitPassword(password: string): Promise<LoginResponse> {
        return this.http.post(this.loginURL, { type: 'password', password });
    }

    async initRegistration(username: string, displayName?: string, email?: string): Promise<GenericResponse> {
        const { data } = await this.http.post(this.registerURL, {
            username,
            displayName,
            email,
        });
        return data;
    }

    async submitRegistrationResponse(registerResponse: any): Promise<GenericResponse> {
        const { data } = await this.http.post(this.registerURL, registerResponse);
        return data;
    }

    async fido2RunGetAssertion(getAssertionRequestEncoded: any): Promise<any> {
        const publicKey = await this.browserHelpers.performGetAssertion(getAssertionRequestEncoded);
        const assertionResponseRaw = await this.browserHelpers.window.navigator.credentials.get({ publicKey });
        const authrAssertion = await Promise.resolve(
            this.browserHelpers.publicKeyCredentialToJSON(assertionResponseRaw),
        );
        return this.submitAuthenticationResponse(authrAssertion);
    }

    async fido2MakeCredentialOptions(): Promise<any> {
        const { data } = await this.http.get<any>(this.fido2MakeCredentialURL);
        return data;
    }

    async fido2MakeCredentialResult(makeCredentialResultEncoded: any): Promise<any> {
        const { data } = await this.http.post<any>(this.fido2MakeCredentialURL, makeCredentialResultEncoded);
        return data;
    }

    async fido2ExecuteMakeCredential(makeCredentialEncoded: any, platformOnly?: boolean, rk?: boolean): Promise<any> {
        const publicKey = this.browserHelpers.preformatMakeCredReq(makeCredentialEncoded);

        if (platformOnly || rk) {
            if (!publicKey.authenticatorSelection) {
                publicKey.authenticatorSelection = {};
            }

            if (platformOnly) {
                publicKey.authenticatorSelection.authenticatorAttachment = 'platform';
            }

            if (rk) {
                publicKey.authenticatorSelection.requireResidentKey = true;
            }
        }

        const attestationResponseRaw = await this.browserHelpers.window.navigator.credentials.create({ publicKey });
        return Promise.resolve(this.browserHelpers.publicKeyCredentialToJSON(attestationResponseRaw));
    }

    async fido2RunMakeCredential(platformOnly?: boolean, rk?: boolean): Promise<any> {
        const makeCredentialOptionsEncoded = await this.fido2MakeCredentialOptions();
        const makeCredentialResultEncoded = await this.fido2ExecuteMakeCredential(
            makeCredentialOptionsEncoded.request,
            platformOnly,
            rk,
        );
        return this.fido2MakeCredentialResult(makeCredentialResultEncoded);
    }

    // FIDO CLIENT INFO
    fido2WebAuthnInfo(): Promise<WebAuthnClientInfo> {
        const result = <WebAuthnClientInfo>{
            WebAuthnIsAvailable: false,
            PlatformAuthenticatorIsAvailable: false,
            ResidentKey: false, // TODO
        };

        return new Promise((resolve) => {
            if (this.browserHelpers.window.PublicKeyCredential) {
                result.WebAuthnIsAvailable = true;
            }

            const currentOS = this.browserHelpers.getOperatingSystem();
            if (currentOS !== 'iOS' && currentOS !== 'Android') {
                result.ResidentKey = true;
            }


            if (
                !this.browserHelpers.window.PublicKeyCredential
                || !this.browserHelpers.window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable
            ) {
                resolve(result);
                return;
            }

            this.browserHelpers.window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(
                (platformIsAvailable: boolean) => {
                    if (platformIsAvailable) {
                        result.PlatformAuthenticatorIsAvailable = true;
                    }
                    resolve(result);
                },
                (rejected: Error) => {
                    resolve(result);
                },
            );
        });
    }

    logout(): Promise<GenericResponse> {
        return this.http.get(this.logoutURL);
    }

    async userIsLoggedIn(): Promise<any> {
        const { data } = await this.http.get(this.userIsLoggedInURL);
        return data;
    }
}
