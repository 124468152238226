import { MutationTree } from 'vuex';
import {
    IUserState, OrganizationStructure, EditOrgNamePayload, SetSubPayload,
} from './types';
import { state as defaultState } from './state';

export const mutations: MutationTree<IUserState> = {
    SET_USERNAME: (state: IUserState, payload: string) => {
        state.username = payload;
    },
    SET_ORGANIZATION_ID: (state: IUserState, payload: string) => {
        state.organizationId = payload;
    },
    SET_RECENT_ORGANIZATIONS: (state: IUserState, payload: string[]) => {
        state.recentOrganizations = payload;
    },
    SET_USERID: (state: IUserState, payload: string) => {
        state.userId = payload;
    },
    SET_USER_TYPE: (state: IUserState, payload: string) => {
        state.type = payload;
    },
    LOGOUT: (state: IUserState) => {
        /* eslint-disable-next-line */
        state = Object.assign(state, {...defaultState, organizationId: state.organizationId, username: state.username});
    },
    ADD_ORGANIZATIONS: (state: IUserState, payload: OrganizationStructure) => {
        state.organizations.push(payload);
    },
    SET_ORGANIZATIONS: (state: IUserState, payload: OrganizationStructure[]) => {
        state.organizations = payload;
    },
    CLEAR_ORGANIZATIONS: (state: IUserState) => {
        state.organizations = [];
    },
    // SET_PENDING_UPGRADE_REQUEST: (state: IUserState, payload: string) => {
    //     state.organizations = state.organizations.map(org => (org.id === payload ? { ...org, pendingUpgradeRequest: true } : org));
    // },
    SET_ORGANIZATION_NAME_BY_ID: (state: IUserState, payload: EditOrgNamePayload) => {
        state.organizations = state.organizations.map(org => (org.id === payload.id ? { ...org, name: payload.name } : org));
    },
    SET_LEGACY_TRUE: (state: IUserState) => {
        state.legacy = true;
    },
    SET_SUBSCRIPTION_BY_ORG: (state: IUserState, payload: SetSubPayload) => {
        state.organizations = state.organizations.map(org => (org.id === payload.orgId ? { ...org, subscription: payload.subscription } : org));
    },
};
