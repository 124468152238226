/* eslint-disable-next-line */
import { getDefaultState } from './index';
import { IRootState } from './types';
import { state as UserState } from './user/state';

export const mutations = {
    SET_LOCALE: (state: IRootState, payload: string) => {
        state.locale = payload;
    },
    CLEAR: (prevState: IRootState) => {
        Object.assign(prevState, getDefaultState());
        // {
        //  ...(prevState.user && { user: { ...UserState, organizationId: prevState.user.organizationId } }),
        // }
    },
    SET_PLUGIN_REFERRAL: (state: IRootState, payload: string|undefined) => {
        state.pluginReferral = payload;
    },
};
