const loginIDInvertedLogo = require('@/assets/v2/login-register/layouts/get-started-a/loginid-logo-inverted.svg');
const loginIDMobileLogo = require('@/assets/v2/Just_Logo.svg');
const loginIDLogo = require('@/assets/img/loginid-logo.svg');
const asliInvertedLogo = require('@/assets/img/aslilogin-logo-white.svg');
const asliMobileLogo = require('@/assets/v2/aslilogin-logo-textless.svg');
const asliLogo = require('@/assets/img/aslilogin-logo.svg');
const visaLogo = require('@/assets/visaimg/visa-logo.png');

export interface vendorInfo {
    name: string;
    dashboardTitle: string;
    logo: any;
    invertedLogo: any;
    mobileLogo: any;
    hasWordpressPlugin: boolean;
    links: vendorLinks;
    contacts: vendorContacts;
    theme: vendorTheme;
}

export interface vendorInfos {
    [k: string]: vendorInfo;
}

export interface vendorContacts {
    emails: vendorEmails;
}

export interface vendorLinks {
    docs: docLinks;
    support: string;
}

export interface docLinks {
    landing: string;
    dw: string;
    createToken: string;
}

export interface vendorEmails {
    sales: string;
    support: string;
}

export interface vendorTheme {
    colors: vendorColors;
}

export interface vendorColors {
    primaryColor: string;
    primaryColorLight: string;
    primaryColorDark: string;
    secondaryColor: string;
    secondaryColorLight: string;
    warningColor: string;
    warningColorDark: string;
    fontColor: string;
}

export const vendor = {
    asli: {
        name: 'Asli',
        dashboardTitle: 'Beta Dashboard | AsliLoginID.io',
        logo: asliLogo,
        invertedLogo: asliInvertedLogo,
        mobileLogo: asliMobileLogo,
        hasWordpressPlugin: false,
        links: {
            docs: {
                landing: 'https://docs.loginid.io',
                dw: 'https://docs.loginid.io/Client-SDKs/Web/web-get-started',
                createToken:
                    'https://docs.loginid.io/Guides/Miscellaneous/api-service-tokens',
            } as docLinks,
            support: 'https://loginid.io/support',
            tos: 'https://loginid.io/terms-of-service',
        } as vendorLinks,
        contacts: {
            emails: {
                sales: 'sales@loginid.io',
                support: 'support@loginid.io',
            } as vendorEmails,
        } as vendorContacts,
        theme: {
            colors: {
                primaryColor: '#18afe5',
                primaryColorLight: '#98cee2',
                primaryColorDark: '#1391be',
                secondaryColor: '#404047',
                secondaryColorLight: '#5d5d75',
                warningColor: '#f70932',
                warningColorDark: '#c50228',
                fontColor: '#8e8e95',
            } as vendorColors,
        } as vendorTheme,
    } as vendorInfo,
    loginid: {
        name: 'LoginID',
        dashboardTitle: 'Beta Dashboard | LoginID.io',
        logo: loginIDLogo,
        invertedLogo: loginIDInvertedLogo,
        mobileLogo: loginIDMobileLogo,
        hasWordpressPlugin: true,
        links: {
            docs: {
                landing: 'https://docs.loginid.io',
                dw: 'https://docs.loginid.io/Client-SDKs/Web/web-get-started',
                createToken:
                    'https://docs.loginid.io/Guides/Miscellaneous/api-service-tokens',
            } as docLinks,
            support: 'https://loginid.io/support',
            tos: 'https://loginid.io/terms-of-service',
        } as vendorLinks,
        contacts: {
            emails: {
                sales: 'sales@loginid.io',
                support: 'support@loginid.io',
            } as vendorEmails,
        } as vendorContacts,
        theme: {
            colors: {
                primaryColor: '#006dff',
                primaryColorLight: '#3b86fa',
                primaryColorDark: '#4f5aee',
                secondaryColor: '#1dd56c',
                secondaryColorLight: '#3EDB81',
                warningColor: '#f70932',
                warningColorDark: '#c50228',
                fontColor: '#4f4f54',
            } as vendorColors,
        } as vendorTheme,
    } as vendorInfo,
    visa: {
        name: 'Visa',
        dashboardTitle: 'Beta Dashboard | Visa',
        logo: visaLogo,
        invertedLogo: loginIDInvertedLogo,
        mobileLogo: loginIDMobileLogo,
        hasWordpressPlugin: false,
        links: {
            docs: {
                landing: 'https://victoria.docs.loginid.io/',
                dw: 'https://victoria.docs.loginid.io/Client-SDKs/Web/web-get-started',
                createToken:
                    'https://victoria.docs.loginid.io/Guides/Miscellaneous/api-service-tokens',
            } as docLinks,
            support: '',
            tos: '',
        } as vendorLinks,
        contacts: {
            emails: {
                sales: '',
                support: '',
            } as vendorEmails,
        } as vendorContacts,
        theme: {
            colors: {
                primaryColor: '#006dff',
                primaryColorLight: '#3b86fa',
                primaryColorDark: '#4f5aee',
                secondaryColor: '#1dd56c',
                secondaryColorLight: '#3EDB81',
                warningColor: '#f70932',
                warningColorDark: '#c50228',
                fontColor: '#4f4f54',
            } as vendorColors,
        } as vendorTheme,
    } as vendorInfo,
} as vendorInfos;
