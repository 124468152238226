import { MutationTree } from 'vuex';
import { IAuthState } from '@/store/auth/types';
import { state as defaultState } from './state';

export const mutations: MutationTree<IAuthState> = {
    LOGIN: (state: IAuthState) => {
        state.isAuthenticated = true;
        state.accessCode = '';
        state.invitationMode = false;
    },
    LOGOUT: (state: IAuthState) => {
        /* eslint-disable-next-line */
        state = Object.assign(state, defaultState);
    },
    SET_INVITATION_MODE: (state: IAuthState, payload) => {
        state.invitationMode = payload;
    },
    SET_EMAIL: (state: IAuthState, payload: string) => {
        state.email = payload;
    },
    SET_ACCESS_CODE: (state: IAuthState, payload: string) => {
        state.accessCode = payload;
    },
};
