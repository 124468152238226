import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LoginIDorASLiRe from '@/components/LoginIDOrAsliRe/LoginIDOrAsliRe.vue';
import VueAnalytics, { set } from 'vue-analytics';
import Typography from '@/components/v3/typography.vue';
import VendorName from '@/components/globals/vendor-name.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import messages from './locale';
import { HttpPlugin } from './services/http.service';

const isDebug = (document.body.dataset.vueAppAnalyticsDebug || '').toLowerCase() === 'true';

Vue.use(VueAnalytics, {
    id: 'UA-140028024-1',
    checkDuplicatedScript: true,
    // not autotracking, manual tracking
    // router,
    debug: {
        sendHitTask: !isDebug,
        enabled: isDebug,
    },
});

require('./filters');

Vue.config.productionTip = false;

Vue.use(HttpPlugin);

/*
Vue.use(TooltipPlugin);
Vue.use(ToastPlugin);
*/
Vue.use(VueI18n);
Vue.component('loginidoraslire', LoginIDorASLiRe);
Vue.component('typography', Typography);
Vue.component('vendor-name', VendorName);

export const i18n = new VueI18n({
    locale: store.getters.locale(),
    fallbackLocale: 'en',
    messages,
});

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    async mounted() {
        function checkSupport() {
            return new Promise((resolve) => {
                let result = 0;
                if (
                    window.PublicKeyCredential
                    && window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable
                ) {
                    window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(
                        (platformIsAvailable) => {
                            if (platformIsAvailable) {
                                result = 1;
                            }
                            resolve(result);
                        },
                        (error) => {
                            resolve(result);
                        },
                    );
                } else {
                    resolve(result);
                }
            });
        }
        try {
            // emulate the old check support feature
            set('dimension1', String(await checkSupport()));
        } catch (error) {
            set('dimension1', 'error');
        }
    },
}).$mount('#app');
