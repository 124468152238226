import { IRootState } from '@/store/types';
import { vendorContacts, vendorLinks, vendorColors } from '@/vendor';
import { GetterTree } from 'vuex';
import { IVendorState } from './types';

export const getters: GetterTree<IVendorState, IRootState> = {
    getVendorName: (state: IVendorState) => (): string => state.vendorInfo.name,
    getVendorLogo: (state: IVendorState) => (): string => state.vendorInfo.logo,
    getInvertedVendorLogo: (state: IVendorState) => (): string => state.vendorInfo.invertedLogo,
    getVendorMobileLogo: (state: IVendorState) => (): string => state.vendorInfo.mobileLogo,
    getHasWordpress: (state: IVendorState) => (): boolean => state.vendorInfo.hasWordpressPlugin,
    getVendorLinks: (state: IVendorState) => (): vendorLinks => state.vendorInfo.links,
    getVendorContacts: (state: IVendorState) => (): vendorContacts => state.vendorInfo.contacts,
    getVendorColors: (state: IVendorState) => (): vendorColors => state.vendorInfo.theme.colors,
};
