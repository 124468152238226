import { MutationTree } from 'vuex';
import { IThemeState } from './types';
import { state as defaultState } from './state';

export const mutations: MutationTree<IThemeState> = {
    SETAMASLI: (state: IThemeState) => {
        state.isAsli = true;
    },
    SETAMNOTASLI: (state: IThemeState) => {
        state.isAsli = false;
    },
};
