



import Vue from 'vue';

export default Vue.extend({
    computed: {
        vendorName(): string {
            return this.$store.getters['vendor/getVendorName']();
        },
    },
});
