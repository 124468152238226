import http from './http.service';

const SERVICE_URL = '/dashboard';

export default class LoginService {
  private http = http;
  async isLoggedIn(): Promise<any> {
      return this.http.get(`${SERVICE_URL}/is-logged-in`);
  }
}
