/* eslint-disable */

function _window(): any {
    // return the global native browser window object
    return window;
}

export class BrowserHelpersService {
    get window(): any {
        return _window();
    }

    get localStorage(): any {
        return _window().localStorag;
    }

    performGetAssertion(getAssertionRequest: any): any {
        getAssertionRequest.challenge = this.window.base64url.decode(getAssertionRequest.challenge);

        if (getAssertionRequest.allowCredentials) {
            for (let allowCred of getAssertionRequest.allowCredentials) {
                allowCred.id = this.window.base64url.decode(allowCred.id);
            }
        }

        return getAssertionRequest;
    }

    preformatMakeCredReq(makeCredentialRequest: any): any {
        makeCredentialRequest.challenge = this.window.base64url.decode(makeCredentialRequest.challenge);
        makeCredentialRequest.user.id = this.window.base64url.decode(makeCredentialRequest.user.id);

        if (makeCredentialRequest.excludeCredentials) {
            for (let excludeCred of makeCredentialRequest.excludeCredentials) {
                excludeCred.id = this.window.base64url.decode(excludeCred.id);
            }
        }

        return makeCredentialRequest;
    }

    publicKeyCredentialToJSON(pubKeyCred: any): any {
        if (pubKeyCred instanceof Array) {
            let arr = [];
            for (let i of pubKeyCred)
                arr.push(this.publicKeyCredentialToJSON(i));

            return arr;
        }

        if (pubKeyCred instanceof ArrayBuffer) {
            return this.window.base64url.encode(pubKeyCred);
        }

        if (pubKeyCred instanceof Object) {
            let obj: any = {};

            for (let key in pubKeyCred) {
                obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key]);
            }

            return obj;
        }

        return pubKeyCred;
    }

    generateRandomBuffer(len: number): Uint8Array {
        len = len || 32;

        let randomBuffer = new Uint8Array(len);
        this.window.crypto.getRandomValues(randomBuffer);

        return randomBuffer;
    }

    generateRandomString(len: number): string {
        return this.window.base64url.encode(this.generateRandomBuffer(len)).slice(0, len);
    }

    getOperatingSystem(): string | null {
        let userAgent = this.window.navigator.userAgent;
        let platform = this.window.navigator.platform;
        let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        let iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'MacOS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }
}
